<template>
    <div>
        <Bookings/>
    </div>
</template>

<script>
import Bookings from '../components/Bookings/Bookings.vue'

export default {
    components: {
        Bookings
    },
    data: () => ({
        carrierData: null,
        bookings: Object,
        loaded: false,
        key: 0
    }),
    async created() {
        // this.loaded = false

        // await this.getBookings()
        // let shipments = await this.$API.shippingInstructions()
        // console.log(shipments)
        // this.loaded = true

    },
    mounted() {

    },
    methods: {
        formatTime(time) {
            let result = null
            if (time) {
                result = new Date(time).toISOString().
                replace(/T/, ' '). // replace T with a space
                replace(/\..+/, '').substr(0, 16)
            }
            return result
        },
        formatUtcTime(time) {
            let result = null
            if (time) {
                result = new Date(time).toISOString().
                replace(/T/, ' '). // replace T with a space
                replace(/\..+/, '')
            }
            return result
        },
        getIcon(state) {
            switch (state) {
                case 'Confirmed':
                    return 'check_circle_outline'
                case 'Declined':
                    return 'highlight_off'
                case 'Requested':
                    return 'pending'
                case 'Cancelled':
                    return 'block'
                case 'Replaced':
                    return 'sync'
                case 'Amended':
                    return 'edit'
            }
        },
        getIconColor(state) {
            switch (state) {
                case 'Confirmed':
                    return 'green'
                case 'Declined':
                    return 'red'
                case 'Requested':
                    return 'blue'
                case 'Cancelled':
                    return 'red'
                case 'Replaced':
                    return 'orange'
                case 'Amended':
                    return 'grey'
            }
        },
        getImage(state) {
            switch (state) {
                case 'MSC':
                    return 'msc.png'
                case 'Maersk':
                    return 'maersk.png'
                case 'Ocean Network Express Pte. Ltd.':
                    return 'one.png'
                case 'DAL':
                    return 'dal.png'
                case 'HAPAG-LLOYD':
                    return 'hapag-lloyd.png'
                case 'CMA CGM':
                    return 'cma-cgm.png'
                case 'PIL':
                    return 'pil.png'
                case 'HAMBURG SÜD':
                    return 'hamburg.jpeg'
            }
        },
        async getBookings() {
            let result = await this.$API.intraBookings({
                limit: 10,
                offset: 0
            })
            console.log(result)
            this.bookings = result
            this.bookings.summary.carrierId = this.bookings.summary.carrierId.sort((a, b) => b.count - a.count)

            this.carrierData = {
                labels: this.bookings.summary.carrierId.map(x => x.keyAsString),
                datasets: [{
                    label: 'Bookings',
                    data: this.bookings.summary.carrierId.map(x => x.count),
                    backgroundColor: 'rgba(191, 31, 55, 0.3)',
                    borderColor: 'rgba(191, 31, 55, 1)',
                    borderWidth: 1
                }]
            }
            this.stateData = {
                labels: this.bookings.summary.state.map(x => x.key),
                datasets: [{
                    label: 'Bookings',
                    data: this.bookings.summary.state.map(x => x.count),
                    backgroundColor: this.bookings.summary.state.map(x => x.key).map(x => this.getIconColor(x)),
                    borderColor: this.bookings.summary.state.map(x => x.key).map(x => this.getIconColor(x)),
                    borderWidth: 0.1
                }]
            }
            console.log(this.carrierData)
            // this.loaded=true
            this.key++

        }
    }
}
</script>
